<div class="container">
  <div class="row">
    <div class="col-md-6 set-column" >
      <span class="back-button">
        <mat-icon>arrow_back_ios</mat-icon>
        <a href="{{customerBaseUrl}}" routerLinkActive="active" ariaCurrentWhenActive="page">
          <b> Back</b></a></span>
      <div class="logo text-center">
        <img src="./././assets/QUP-signup.png" class="my-4 login-logo" />
      </div>
      <div class="col-md-12 text-center">
        <span class="mx-y">
          <h1><b>Customer Sign Up</b></h1>
        </span>
      </div>

      <div class="my-4">
        <form [formGroup]="signUpForm" class="example-form" (ngSubmit)="onsignUpFormSubmit()">
          <div class="row">
            <div class="col-md-6">
              <p style="margin-bottom: 0px;">First Name</p>
              <mat-form-field class="example-full-width custom-field custom-form-field" appearance="outline" >
                <input matInput formControlName="firstName" required/>
              </mat-form-field>
              <div class="error-container">
                <mat-error *ngFor="let validation of validation_messages.firstName">
                  <mat-error class="error-message" *ngIf="
                      signUpForm.get('firstName')?.hasError(validation.type) &&
                      (signUpForm.get('firstName')?.dirty ||
                        signUpForm.get('firstName')?.touched)
                    ">
                    {{ validation.message }}</mat-error>
                </mat-error>
                <mat-error class="error-message" *ngIf="
                    signUpForm.get('firstName')?.hasError('pattern') &&
                    (signUpForm.get('firstName')?.dirty ||
                      signUpForm.get('firstName')?.touched)
                  ">
                  Only alphabets & spaces are allowed
                </mat-error>
              </div>
            </div>
            <div class="col-md-6">
              <p style="margin-bottom: 0px;">Last Name</p>
              <mat-form-field class="example-full-width custom-field custom-form-field" appearance="outline">
                <input matInput formControlName="lastName"  required />
              </mat-form-field>
              <div class="error-container">
                <mat-error *ngFor="let validation of validation_messages.lastName">
                  <mat-error class="error-message" *ngIf="
                      signUpForm.get('lastName')?.hasError(validation.type) &&
                      (signUpForm.get('lastName')?.dirty ||
                        signUpForm.get('lastName')?.touched)
                    ">
                    {{ validation.message }}</mat-error>
                </mat-error>
                <mat-error class="error-message" *ngIf="
                    signUpForm.get('lastName')?.hasError('pattern') &&
                    (signUpForm.get('lastName')?.dirty ||
                      signUpForm.get('lastName')?.touched)
                  ">
                  Only alphabets & spaces are allowed
                </mat-error>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p style="margin-bottom: 0px;">User Name</p>
              <mat-form-field class="example-full-width custom-field custom-form-field" appearance="outline">
                <input matInput formControlName="userName"  required />
              </mat-form-field>
              <div class="error-container">
                <mat-error *ngIf="
                    signUpForm.get('userName')?.invalid &&
                    (signUpForm.get('userName')?.dirty ||
                      signUpForm.get('userName')?.touched)
                  ">
                  <span class="error-message" *ngIf="signUpForm.get('userName')?.hasError('required')">
                    User Name is required
                  </span>
                  <span class="error-message" *ngIf="signUpForm.get('userName')?.hasError('pattern')">
                    Only alphabets and numbers
                  </span>
                </mat-error>
                <div class="error-message" style="color: #f44336"
                  *ngIf="signUpForm.get('userName')?.hasError('userNameExists')">
                  User Name already exists!
                </div>
                <div class="error-message" style="color: green" *ngIf="
                    !signUpForm.get('userName')?.hasError('userNameExists') &&
                    signUpForm.get('userName')?.value?.length > 0 &&
                    !signUpForm.get('userName')?.hasError('pattern')
                  ">
                  User Name is available.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <p style="margin-bottom: 0px;">Phone Number</p>
              <mat-form-field class="example-full-width custom-field custom-form-field" appearance="outline">
                <input matInput formControlName="phoneNumber"  required
                  (input)="formatPhoneNumber($event)" (blur)="updatePhoneNumberValidity()" />
              </mat-form-field>
              <div class="error-container">
                <mat-error *ngFor="let validation of validation_messages.phoneNumber">
                  <mat-error class="error-message" *ngIf="
                      signUpForm
                        .get('phoneNumber')
                        ?.hasError(validation.type) &&
                      (signUpForm.get('phoneNumber')?.dirty ||
                        signUpForm.get('phoneNumber')?.touched)
                    ">
                    {{ validation.message }}</mat-error>
                </mat-error>
                <mat-error *ngIf="
                    signUpForm.get('phoneNumber')?.hasError('invalidPattern')
                  ">
                  Phone number is required
                </mat-error>
                <mat-error class="error-message" *ngIf="signUpForm.get('phoneNumber')?.hasError('pattern')">
                  Please enter valid phone number
                </mat-error>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p style="margin-bottom: 0px;">Email</p>
              <mat-form-field class="example-full-width custom-field custom-form-field" appearance="outline">
                <input matInput formControlName="emailAddress"  required />
              </mat-form-field>
              <div class="error-container">
                <mat-error *ngFor="let validation of validation_messages.emailAddress">
                  <mat-error class="error-message" *ngIf="
                      signUpForm
                        .get('emailAddress')
                        ?.hasError(validation.type) &&
                      (signUpForm.get('emailAddress')?.dirty ||
                        signUpForm.get('emailAddress')?.touched)
                    ">
                    {{ validation.message }}</mat-error>
                </mat-error>
                <mat-error class="error-message" *ngIf="
                    signUpForm.get('emailAddress')?.hasError('pattern') &&
                    (signUpForm.get('emailAddress')?.dirty ||
                      signUpForm.get('emailAddress')?.touched)
                  ">
                  Please enter valid email address
                </mat-error>
              </div>
              <p style="margin-bottom: 0px;">Password</p>
              <mat-form-field class="example-full-width custom-field custom-form-field" appearance="outline">
                <input matInput formControlName="password"  required
                  [type]="hideCurrentPassword ? 'password' : 'text'" />
                <button mat-icon-button matSuffix (click)="toggleVisibility('password')" type="button">
                  <mat-icon>{{
                    hideCurrentPassword ? "visibility_off" : "visibility"
                    }}</mat-icon>
                </button>
              </mat-form-field>
              <div class="error-container">
                <mat-error *ngFor="let validation of validation_messages.password">
                  <mat-error class="error-message" *ngIf="
                      signUpForm.get('password')?.hasError(validation.type) &&
                      (signUpForm.get('password')?.dirty ||
                        signUpForm.get('password')?.touched)
                    ">
                    {{ validation.message }}</mat-error>
                </mat-error>
                <mat-error class="error-message" *ngIf="signUpForm.get('password')?.hasError('pattern')">
                  <ul>
                    <li>Please enter minimum of 8 characters</li>
                    <li>Include at least one lowercase letter</li>
                    <li>Include at least one uppercase letter</li>
                    <li>Include at least one digit</li>
                    <li>
                      Include at least one special character({{ "!@#$%^&*" }})
                    </li>
                  </ul>
                </mat-error>
              </div>
              <p style="margin-bottom: 0px;">Confirm Password</p>
              <mat-form-field class="example-full-width custom-field custom-form-field" appearance="outline">
                <input matInput formControlName="confirmPassword"  required
                  [type]="hideConfirmPassword ? 'password' : 'text'" />
                <button mat-icon-button matSuffix (click)="toggleVisibility('confirmPassword')" type="button">
                  <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
              </mat-form-field>
              <div class="error-container">
                <mat-error *ngFor="let validation of validation_messages.confirmPassword">
                  <mat-error class="error-message" *ngIf="signUpForm.get('confirmPassword')?.hasError(validation.type) &&
                                            (signUpForm.get('confirmPassword')?.dirty ||
                                              signUpForm.get('confirmPassword')?.touched)">
                    {{ validation.message }}</mat-error>
                </mat-error>
                <div class="errormsg"
                  *ngIf="(signUpForm.get('confirmPassword')?.value?.length >0 )&& (signUpForm.get('confirmPassword')?.value !== signUpForm.get('password')?.value)">
                  <p> Password & Confirm Password are not same </p>
                </div>
              </div>
            </div>
            <mat-checkbox formControlName="checkBox" required>I agree to QUP's <a href="https://qupstyle.co/privacy-policy/" target="_blank">Privacy Policy</a> and <a href="https://qupstyle.co/terms-of-service/" target="_blank">Terms and Conditions</a></mat-checkbox>
          </div>
          <!-- <div class="row">
            <div class="col-md-6">
              <mat-slide-toggle>Remember me</mat-slide-toggle>
            </div>
          </div> -->
          <button mat-raised-button *ngIf="!signUpForm.valid" color="primary" class="my-4 example-full-width"
            style="height: 50px; border-radius: 10px" type="submit" [disabled]="!signUpForm.valid">
            Sign Up
          </button>
          <!-- <button mat-raised-button class="my-4 example-full-width" style="
              height: 50px;
              background-color: #525eb1;
              color: #fff;
              border-radius: 10px;
            " type="submit" *ngIf="signUpForm.valid" [disabled]="isButtonDisabled">
            Sign Up
          </button> -->
          <button
            mat-raised-button
            *ngIf="signUpForm.valid"
            [disabled]="isButtonDisabled"
            [style.background-color]="buttonColor"
            class="my-4 example-full-width"
            style="height: 50px; border-radius: 10px; color: #fff"
            (click)="onsignUpFormSubmit()"
            type="submit">
            Sign Up
          </button>
        </form>
        <!-- <div
          class="row"
          style="text-align: center; display: flex; align-items: center"
        >
          <div class="col-md-4">
            <mat-divider></mat-divider>
          </div>
          <div class="col-md-4">or sign in with</div>
          <div class="col-md-4">
            <mat-divider></mat-divider>
          </div>
        </div> -->
        <div>
          <!-- <button mat-stroked-button color="primary" class="my-4 btn-style">
            <img src="./././assets/images/google-icon.png" />
            <span class="mx-3">Google</span>
          </button>
          <button mat-stroked-button color="primary" class="btn-style">
            <img src="./././assets/images/facebook-icon.png" />
            <span class="mx-3">Facebook</span>
          </button> -->
          <p style="text-align: center" class="my-3">
            Already have an account?
            <a href="{{customerBaseUrl}}" routerLinkActive="active" ariaCurrentWhenActive="page"><b> Sign In
                here</b></a>
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="vl col-md-1"></div> -->
    <div class="col-md-6">
      <img src="./././assets/images/signin-img.png" class="image-display" style="width: 100%; height: 100vh; object-fit: contain;" />
    </div>
  </div>
</div>
